@import "media-queries";

/**** MISCELLANEOUS ****/

// Collapse timescale in mobile mode
.Layout-timeScaleContainer {
  @media @below-md {
    width: 50px !important;
  }
}

// Allow text to be put on more lines in appointment cards
.appointment-text-wrap > div > div > div {
  white-space: normal;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

/* Opacity on hover appointments */
.max-opacity-on-hover:hover {
  opacity: 1 !important;
}

/**** AGENDA LIGHT AND DARK THEME COLORS ****/

/**** AVAILABILITY DISPLAY ****/

html {
  --project-disabled-color: rgba(0, 0, 0, 0.06);
  --project-darker-disabled-color: rgba(0, 0, 0, 0.55);
  --places-disabled-color: rgba(255, 0, 0, 0.15);
  --places-darker-disabled-color: rgba(200, 0, 0, 0.55);
  --stewards-disabled-color: rgba(0, 0, 255, 0.17);
  --stewards-darker-disabled-color: rgba(0, 0, 255, 0.55);

  &[class$="dark-mode"] {
    --project-disabled-color: rgba(255, 255, 255, 0.1);
    --project-darker-disabled-color: rgba(255, 255, 255, 0.55);
    --places-disabled-color: rgba(255, 100, 100, 0.25);
    --places-darker-disabled-color: rgba(200, 150, 150, 100);
    --stewards-disabled-color: rgba(100, 100, 255, 0.25);
    --stewards-darker-disabled-color: rgba(150, 150, 255, 100);
  }

  .availabilitiesColors() {
    .project-disabled-date {
      background: var(--project-disabled-color);
    }

    .places-disabled-date {
      background-image: repeating-linear-gradient(
        to left top,
        transparent,
        transparent 25%,
        var(--places-disabled-color) 25%,
        var(--places-disabled-color) 50%
      );
    }

    .stewards-disabled-date {
      background-image: repeating-linear-gradient(
        to left top,
        transparent,
        transparent 25%,
        var(--stewards-disabled-color) 25%,
        var(--stewards-disabled-color) 50%
      );
    }

    .project-places-disabled-date {
      background-image: repeating-linear-gradient(
        to left top,
        var(--project-disabled-color),
        var(--project-disabled-color) 25%,
        var(--places-disabled-color) 25%,
        var(--places-disabled-color) 50%
      );
    }

    .project-stewards-disabled-date {
      background-image: repeating-linear-gradient(
        to left top,
        var(--project-disabled-color),
        var(--project-disabled-color) 25%,
        var(--stewards-disabled-color) 25%,
        var(--stewards-disabled-color) 50%
      );
    }

    .stewards-places-disabled-date,
    .places-stewards-disabled-date {
      background-image: repeating-linear-gradient(
        to left top,
        transparent,
        transparent 25%,
        var(--places-disabled-color) 25%,
        var(--places-disabled-color) 37.5%,
        var(--stewards-disabled-color) 37.5%,
        var(--stewards-disabled-color) 50%
      );
    }

    .project-stewards-places-disabled-date,
    .project-places-stewards-disabled-date {
      background-image: repeating-linear-gradient(
        to left top,
        var(--project-disabled-color),
        var(--project-disabled-color) 25%,
        var(--places-disabled-color) 25%,
        var(--places-disabled-color) 37.5%,
        var(--stewards-disabled-color) 37.5%,
        var(--stewards-disabled-color) 50%
      );
    }

    .grouping-button.ant-tag-checkable-checked {
      color: unset;
    }

    /* Ant tag agenda grouping buttons colors */

    .places-grouping.ant-tag-checkable:not(.ant-tag-checkable-checked):hover {
      color: var(--places-darker-disabled-color);
    }

    .places-grouping.ant-tag-checkable-checked,
    .places-grouping.ant-tag-checkable:active {
      background-color: var(--places-disabled-color);
    }

    .stewards-grouping.ant-tag-checkable:not(.ant-tag-checkable-checked):hover {
      color: var(--stewards-darker-disabled-color);
    }

    .stewards-grouping.ant-tag-checkable-checked,
    .stewards-grouping.ant-tag-checkable:active {
      background-color: var(--stewards-disabled-color);
    }

    .category-grouping.ant-tag-checkable:not(.ant-tag-checkable-checked):hover {
      color: var(--project-darker-disabled-color);
    }

    .category-grouping.ant-tag-checkable-checked,
    .category-grouping.ant-tag-checkable:active {
      background-color: var(--project-darker-disabled-color);
      color: #fff;
    }
  }

  .scheduler-wrapper {
    // General background and borders
    .MuiTableCell-root,
    .DayScaleEmptyCell-emptyCell {
      background: var(--colorBgContainer);
      border-color: var(--colorBorderSecondary) !important;
      border-right-color: var(--colorBorder) !important;
      border-left-color: var(--colorBorder) !important;
    }

    // Main Layout borders
    .MainLayout-ordinaryHeaderBorder,
    .MainLayout-ordinaryLeftPanelBorder,
    .Toolbar-toolbar,
    .MainLayout-brightHeaderBorder {
      border-color: var(--colorBorder) !important;
    }

    // Bigger border for the top
    .MainLayout-ordinaryHeaderBorder,
    .MainLayout-brightHeaderBorder {
      border-width: 2px !important;
    }

    // Texts on the up and left side
    .Label-text,
    .Cell-dayOfWeek,
    .Cell-dayOfMonth,
    .Cell-text {
      color: var(--text) !important;
      opacity: 0.6;

      &.Cell-highlightedText {
        color: var(--noe-primary) !important;
        opacity: 1;
      }
    }

    // Borders around appointments
    .Appointment-appointment {
      border-color: var(--colorBgContainer);
    }

    .availabilitiesColors();
  }
  .availabilitiesColors();
}
